<template>
  <footer class="app-footer">
    <div class="content-wrapper">
      <div class="footer-column">
        <p class="text">
          <img
            class="logo-image"
            src="/icons/logo-reverse.png"
            alt="unit logo"
          />
        </p>
        <p class="text">
          Мы предоставляем лучший доступ для вашей комфортной работы
        </p>
        <p class="text">Unit. 2022</p>
      </div>
      <div class="footer-column">
        <p class="text">
          <a
            href="https://storage.yandexcloud.net/agreements/policy.pdf"
            target="_blank"
          >
            Политика обработки персональных данных
          </a>
        </p>
        <p class="text">
          <a
            href="https://storage.yandexcloud.net/agreements/rules.pdf"
            target="_blank"
          >
            Правила использования сервиса
          </a>
        </p>
      </div>
      <div class="footer-column">
        <p class="text">
          <a href="https://npd.nalog.ru/#start" target="_blank">
            Официальная инструкция от ФНС России
          </a>
        </p>
        <p class="text">
          <a href="https://npd.nalog.ru/faq/" target="_blank">
            База знаний о специальном налоговом режиме
          </a>
        </p>
      </div>
      <div class="footer-column">
        <p></p>
        <p></p>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.app-footer {
  display: none;

  @media screen and (min-width: 720px) {
    display: block;
    background-color: $color-black;
    color: $color-white;
    padding: 0 15px;
  }
}

.content-wrapper {
  display: flex;
  margin: 50px auto;
  max-width: 1160px;
}

.footer-column {
  flex-grow: 1;
  flex-basis: 25%;
  padding-right: 40px;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.55;

  & > .text {
    margin-bottom: 20px;
  }

  & > .text a {
    color: inherit;
  }
}

.logo-image {
  vertical-align: top;
  height: 43.5px;
}
</style>
