const URL_CLEAR = 'https://noco.theunit.ru/flask';
const URL = URL_CLEAR + '/api/';
export class API {
  /** @method get */
  static readonly ORDERS = URL + 'suborders/';

  /** @method get */
  static readonly SKILLS = URL + 'specializations/';

  /** @method post @method put */
  static readonly WORKER = URL + 'worker/';
}
