import { defineStore } from 'pinia';

// === 2fa Store ===

export const useOrderStore = defineStore('order', {
  state: () => ({
    title: '',
    success: false,
  }),

  getters: {
    getTitle: (state) => state.title,
    getState: (state) => state.success,
  },

  actions: {
    setTitle(title: string) {
      this.title = title;
    },
    submitOrder() {
      this.success = true;
    },
    reset() {
      this.success = false;
    },
  },
});
