import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '../views/OrdersView.vue';
import { Route } from '@/router/types';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: Route.OrdersList,
    component: HomeView,
    meta: {
      title: 'Задания',
    },
  },
  {
    path: '/:id',
    name: Route.Order,
    component: () => import('@/views/OrderView.vue'),
    meta: {
      title: 'Задания',
      showBack: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
